var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',[_c('div',{staticClass:"container-fluid border_nav"},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-3 d-flex align-items-center justify-content-center"},[_c('div',{on:{"click":function($event){$event.preventDefault();_vm.$router.push('/').catch(() => {})}}},[_c('img',{staticClass:"logo",attrs:{"src":"/image/logo.png"}})])]),_vm._m(0),_c('div',{staticClass:"col-2 d-flex align-items-center justify-content-end hamberger"},[_c('i',{staticClass:"fa fa-bars fa-2x",on:{"click":function($event){return _vm.show_list_item()}}})])])])]),_c('div',{class:[
      _vm.checkclickhamberger === true ? 'show_list_item' : 'hide_list_item',
    ]},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('i',{staticClass:"fa fa-times fa-2x",on:{"click":function($event){return _vm.show_list_item()}}})]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('ul',[_c('li',[_c('div',{style:({ color: _vm.$route.name == 'Home' ? '#688037' : '#8f9194' }),on:{"click":[function($event){$event.preventDefault();_vm.$router.push('/').catch(() => {})},_vm.closehamberger]}},[_vm._v(" Home ")])]),_c('li',[_c('div',{staticClass:"about_us",style:({ color: _vm.$route.name == 'About' ? '#688037' : '#8f9194' }),on:{"click":[function($event){$event.preventDefault();_vm.$router.push('/about').catch(() => {})},_vm.closehamberger]}},[_vm._v(" About Us ")])]),_c('li',{staticClass:"dropdown",on:{"click":function($event){return _vm.toggle_dropdown()}}},[_c('div',[_c('font',{class:[
                _vm.checkclickdropdown === true ? 'light_green_color' : '',
              ],style:({
                color:
                  _vm.$route.name == 'Seasoning_Blends' ||
                  _vm.$route.name == 'Industrial_Products'
                    ? '#688037'
                    : '#8f9194',
              })},[_vm._v("Products")]),_c('div',{class:[
                _vm.checkclickdropdown === true
                  ? 'show_dropdown'
                  : 'hide_dropdown',
              ]},[_c('div',{style:({
                  color:
                    _vm.$route.name == 'Seasoning_Blends' ? '#688037' : '#8f9194',
                }),on:{"click":function($event){$event.preventDefault();_vm.$router.push('/seasoning_blends').catch(() => {})}}},[_vm._v(" Seasoning Blends ")]),_c('div',{style:({
                  color:
                    _vm.$route.name == 'Industrial_Products'
                      ? '#688037'
                      : '#8f9194',
                }),on:{"click":function($event){$event.preventDefault();_vm.$router.push('/industrial_products').catch(() => {})}}},[_vm._v(" Industrial Products ")])])],1)]),_c('li',[_c('div',{style:({
              color: _vm.$route.name == 'Recipes' ? '#688037' : '#8f9194',
            }),on:{"click":function($event){$event.preventDefault();_vm.$router.push('/recipes').catch(() => {})}}},[_vm._v(" Recipes ")])]),_c('li',[_c('div',{style:({
              color: _vm.$route.name == 'Contact_US' ? '#688037' : '#8f9194',
            }),on:{"click":function($event){$event.preventDefault();_vm.$router.push('/contact_us').catch(() => {})}}},[_vm._v(" Contact Us ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-7 d-flex align-items-center"},[_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-3"},[_c('a',{attrs:{"href":"https://www.facebook.com/SpoonyThailand","target":"_blank"}},[_c('img',{staticClass:"icon",attrs:{"src":"/image/navbar_facebook.svg"}})])]),_c('div',{staticClass:"col-3"},[_c('a',{attrs:{"href":"https://lin.ee/cbFKxdY","target":"_blank"}},[_c('img',{staticClass:"icon",attrs:{"src":"/image/navbar_line.svg"}})])]),_c('div',{staticClass:"col-3"},[_c('a',{attrs:{"href":"https://www.instagram.com/spoonythailand/?hl=en","target":"_blank"}},[_c('img',{staticClass:"icon",attrs:{"src":"/image/navbar_instagram.svg"}})])]),_c('div',{staticClass:"col-3"},[_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCsEEqKOPnH9lKZkkHcgz2ZQ","target":"_blank"}},[_c('img',{staticClass:"icon",attrs:{"src":"/image/navbar_youtube.svg"}})])])])])
}]

export { render, staticRenderFns }