<template>
  <div>
    <Navbar />

    <div class="d-none">
      {{ $route.path }}
    </div>

    <!-- banner + Home -->
    <div class="home_pc">
      <vueper-slides
        class="no-shadow"
        :slide-ratio="2.8 / 8.9"
        autoplay
        fade
        :touchable="false"
        lazy
        lazy-load-on-drag
      >
        <vueper-slide
          v-for="(slide, key) in slides_home"
          :key="key"
          :content="slide.content"
        />
      </vueper-slides>
    </div>

    <div class="home_moblie">
      <vueper-slides
        class="no-shadow"
        :slide-ratio="2.8 / 9"
        autoplay
        fade
        :arrows="false"
        :touchable="false"
        lazy
        lazy-load-on-drag
      >
        <vueper-slide
          v-for="(slide, key) in slides_home"
          :key="key"
          :content="slide.content"
        />
      </vueper-slides>
    </div>

    <!-- {{ innerWidth }} -->

    <!-- about-us -->
    <div class="content_box">
      <div class="topic">
        <font class="dark_green">ABOUT </font>
        <font class="light_green">US</font>
      </div>

      <div class="d-flex flex-lg-nowrap align-items-center">
        <div class="about_us_detail">
          {{ details1 }}
          <br />
          <br />
          {{ details2 }}
          <br />
          <br />
          {{ details3 }}
          <br />
          <br />
          {{ details4 }}
        </div>

        <div class="about_us_image">
          <img src="/image/about_us_image.jpg" />
        </div>
      </div>

      <div class="about_us_logo">
        <img class="about_us_logo1" src="/image/about_us_logo.png" />
        <img class="about_us_logo2" src="/image/about_us_logo2.png" />
      </div>
    </div>

    <!-- products -->
    <div class="content_box products">
      <div class="topic">
        <font class="dark_green">PRODUCTS</font>
      </div>

      <div class="load_change_image">
        <img src="/image/products1.1.png" />
        <img src="/image/products2.1.png" />
        <img src="/image/products3.1.png" />
      </div>

      <div class="products_pc">
        <div class="products_details">
          <div class="container-fluid">
            <div class="row">
              <div
                class="col-sm-4"
                align="center"
                v-for="(data, key) in arraydata_products"
                :key="key"
              >
                <div class="best_seller" v-if="data.best_seller !== ''">
                  {{ data.best_seller }}
                </div>

                <div class="not_best_seller" v-else></div>

                <div class="d-flex flex-column">
                  <div class="image_wrapper">
                    <img
                      :src="data.image"
                      @mouseover="data.image = mouseOver(data.change_image)"
                      @mouseleave="data.image = mouseLeave(data.main_image)"
                      @click.prevent="
                        $router
                          .push(`/products_detail/${data.id}`)
                          .catch(() => {})
                      "
                    />
                  </div>

                  <div
                    class="d-flex flex-wrap justify-content-center align-items-end"
                  >
                    <font
                      class="loadpage_products_detail"
                      @click.prevent="
                        $router
                          .push(`/products_detail/${data.id}`)
                          .catch(() => {})
                      "
                    >
                      {{ data.detail }}
                    </font>

                    <font>
                      <!-- {{ data.price }} -->
                    </font>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="products_moblie">
        <vueper-slides
          class="no-shadow"
          :slide-ratio="10.5 / 9"
          :bullets="false"
          :touchable="false"
          lazy
          lazy-load-on-drag
        >
          <vueper-slide
            v-for="(slide, key) in slides_products"
            :key="key"
            :content="slide.content"
            :link="slide.link"
          />
        </vueper-slides>
      </div>
    </div>

    <!-- recommend-recipes -->
    <div class="recommend_recipes_pc">
      <div class="wrapper_video">
        <video autoplay muted loop playsinline class="item_video">
          <source src="/video/video1.mp4" type="video/mp4" />
        </video>
        <div class="content_box recommend_recipes">
          <div class="topic">
            <font class="dark_green">RECOMMEND </font>
            <font class="light_green">RECIPES</font>
          </div>

          <div class="recommend_recipes_details">
            <div class="container-fluid">
              <div class="row">
                <div
                  class="col-sm-4"
                  align="center"
                  v-for="(data, key) in arraydata_recipes"
                  :key="key"
                >
                  <a :href="data.link" target="_blank">
                    <img :src="data.image" />
                  </a>
                  <br /><br />
                  <font class="text-center">
                    <a :href="data.link" target="_blank">
                      {{ data.name }}
                    </a>
                  </font>
                </div>
              </div>
            </div>

            <div class="recommend_recipes_button">
              <font @click.prevent="$router.push('/recipes').catch(() => {})"
                >VIEW ALL</font
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="recommend_recipes_moblie">
      <div class="content_box recommend_recipes">
        <div class="topic">
          <font class="dark_green">RECOMMEND </font>
          <font class="light_green">RECIPES</font>
        </div>

        <div class="recommend_recipes_details">
          <vueper-slides
            class="no-shadow"
            :slide-ratio="7 / 8"
            :bullets="false"
            :touchable="false"
            lazy
            lazy-load-on-drag
          >
            <vueper-slide
              v-for="(slide, key) in slides_recommend_recipes"
              :key="key"
              :content="slide.content"
              :link="slide.link"
              target="_blank"
            />
          </vueper-slides>
        </div>
      </div>
    </div>

    <!-- where-to-buy -->
    <div class="where_to_buy">
      <div class="topic">
        <font class="dark_green">WHERE TO </font>
        <font class="light_green">BUY ?</font>
      </div>

      <div class="where_to_buy_details">
        <div class="container-fluid">
          <div class="row">
            <div
              class="col-6 col-md-3 d-flex align-items-center justify-content-center"
              align="center"
              v-for="(data, key) in arraydata_wheretobuy"
              :key="key"
            >
              <a :href="data.link" target="_blank">
                <img :src="data.image" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "Home",

  components: {
    Navbar,
    VueperSlides,
    VueperSlide,
  },

  data() {
    return {
      first_page_load: false,
      mouse_hover: true,

      details1: `Spoony, a premium brand under the signature brand from “Double Ducks Brand" the leading spices and herbs manufacturer since 2002.`,
      details2: `Spoony offers you a selection of premium spice from all around the world with highly innovative and globally accepted technology.`,
      details3: `All ingredients of Spoony are 100% natural without any preservatives, MSG, or other additives to ensure that our ingredients retain their original taste and aromas. `,
      details4: `All this to provide the best experience to our valued customers, making them feel as if a  gourmet chef is right there with them in  their kitchens.`,
      // innerWidth: window.innerWidth,

      arraydata_products: [
        {
          id: "1",
          best_seller: "BEST SELLER",
          image: "/image/products1.0.png",
          main_image: "/image/products1.0.png",
          change_image: "/image/products1.1.png",
          detail: "CHINESE FIVE SPICE BLEND",
          price: "฿ 39.00",
        },
        {
          id: "2",
          best_seller: "BEST SELLER",
          image: "/image/products2.0.png",
          main_image: "/image/products2.0.png",
          change_image: "/image/products2.1.png",
          detail: "CURRY POWDER",
          price: "฿ 39.00",
        },
        {
          id: "3",
          best_seller: "",
          image: "/image/products3.0.png",
          main_image: "/image/products3.0.png",
          change_image: "/image/products3.1.png",
          detail: "TURMERIC POWDER",
          price: "฿ 39.00",
        },
      ],

      arraydata_recipes: [
        {
          image: "/image/recommend-recipes1.0.jpg ",
          name: "Stir Fried Crab with Curry Powder",
          link: "https://www.youtube.com/watch?v=PZ_eEWqmQp8",
        },
        {
          image: "/image/recommend-recipes2.0.jpg ",
          name: "Fried Fish with Turmeric Powder",
          link: "https://www.youtube.com/watch?v=CPh0O-cMMTk",
        },
        {
          image: "/image/recommend-recipes3.0.jpg ",
          name: "Five Spice Egg",
          link: "https://www.youtube.com/watch?v=OkFoCETCDGM",
        },
      ],

      arraydata_wheretobuy: [
        {
          link: "https://lin.ee/cbFKxdY",
          image: "/image/where-to-buy1.0.png ",
        },
        {
          link: "https://www.facebook.com/SpoonyThailand",
          image: "/image/where-to-buy2.0.png ",
        },
        {
          link:
            "https://shopee.co.th/spoonythailand?categoryId=9205&itemId=5939823546",
          image: "/image/where-to-buy3.0.png ",
        },
        {
          link:
            "https://www.lazada.co.th/shop/spoonythailand-tastetopthailand/?spm=a2o4m.pdp.seller.1.1d6671246hoDv5&itemId=1231400292&channelSource=pdp",
          image: "/image/where-to-buy4.0.png ",
        },
      ],

      slides_home: [
        {
          content: `
    <div class="home">
      <img src="/image/home_image01.jpg" class="image_home" />
      <div class="home_detail">
        <img src="/image/logo.png" />
        <br />
        <font v-if="this.first_page_load == true" class="font-weight-bold">PREMIUM QUALITY SPICES & SEASONING</font>
      </div>
    </div>

    <style scoped>
    .home {
      position: relative;
    }

    .image_home {
      width: 100%;
      background-position: center;
      background-size: cover;
    }

    .home_detail {
      width: 1000px;
      position: absolute;
      top: 5%;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      line-height: 3;
    }

    .home_detail img {
      width: 10%;
    }

    .home_detail font {
     font-size: 20px;
     animation-name: animations;
     animation-duration: 1s;
     letter-spacing : 1.8px;
    }

    @keyframes animations {0% {opacity: 0; margin-left: 7rem;} 100% {opacity: 1;}}

    @media screen and (max-width: 1760px) {
     .home_detail img {
      width: 9%;
     }

    .home_detail font {
      font-size: 19px;
     }
    }

    @media screen and (max-width: 1600px) {
     .home_detail img {
      width: 8%;
     }

    .home_detail font {
      font-size: 18px;
     }
    }

    @media screen and (max-width: 1440px) {
     .home_detail img {
      width: 7%;
     }

     .home_detail font {
      font-size: 16px;
     }
    }

    @media screen and (max-width: 1280px) {
     .home_detail img {
      width: 6%;
     }

     .home_detail font {
      font-size: 15px;
     }

     @keyframes animations {0% {opacity: 0; margin-left: 3.5rem;} 100% {opacity: 1;}}
    }

    @media screen and (max-width: 834px) {
      .home_detail {
       line-height: 2;
      }

      .home_detail img {
       width: 4%;
      }

      .home_detail font {
        font-size: 10px;
        letter-spacing : 0px;
      }
    }

    @media screen and (max-width: 428px) {
     .home_detail {
       line-height: 1.5;
      }

      .home_detail img {
        width: 2.5%;
      }

      .home_detail font {
        font-size: 6px;
      }
    }

    @media screen and (max-width: 375px) {
     .home_detail {
       line-height: 1;
      }

      .home_detail img {
        width: 2%;
      }

      .home_detail font {
        font-size: 8px;
      }
    }
    </style>
    `,
        },
        {
          content: `<img src="/image/home_image02.jpg" style="width: 100%" />`,
        },
        {
          content: `<img src="/image/home_image03.jpg" style="width: 100%" />`,
        },
        {
          content: `<img src="/image/home_image04.JPG" style="width: 100%" />`,
        },
      ],
      slides_products: [
        {
          content: `<div style="display: inline; font-size: 25px; margin-right: 6rem; padding: 0.1rem 0.5rem; color: #ffffff; background: #abc93b; font-family:Sathu; font-weight: normal;">BEST SELLER</div><img src="/image/products1.0.png" style="width:50%; margin-top:0.5rem;" /><br><br><div style="font-size:20px; color:#8F9194; font-family: MyriadVariableConcept; font-weight: 550;">CHINESE FIVE SPICE BLEND<br>฿ 39.00</div>`,
          link: "/products_detail/1",
        },
        {
          content: `<div style="display: inline; font-size: 25px; margin-right: 6rem; padding: 0.1rem 0.5rem; color: #ffffff; background: #abc93b; font-family: Sathu; font-weight: normal;">BEST SELLER</div><img src="/image/products2.0.png" style="width:50%; margin-top:0.5rem;" /><br><br><div style="font-size:20px; color:#8F9194; font-family: MyriadVariableConcept; font-weight: 550;">CURRY POWDER<br>฿ 39.00</div>`,
          link: "/products_detail/2",
        },
        {
          content: `<div style="margin-top:3rem;"></div><img src="/image/products3.0.png" style="width:50%;" /><br><br><div style="font-size:20px; color:#8F9194; font-family: MyriadVariableConcept; font-weight: 550;">TURMERIC POWDER<br>฿ 39.00</div>`,
          link: "/products_detail/3",
        },
      ],
      slides_recommend_recipes: [
        {
          content: `<img src="/image/recommend-recipes1.0.jpg" style="width:75%" /><br><br><div style="font-size:20px; color:#8F9194; font-family: MyriadVariableConcept; font-weight: 550;">Stir Fried Crab with Curry Powder</div>`,
          link: "https://www.youtube.com/watch?v=PZ_eEWqmQp8",
        },
        {
          content: `<img src="/image/recommend-recipes2.0.jpg" style="width:75%" /><br><br><div style="font-size:20px; color:#8F9194; font-family: MyriadVariableConcept; font-weight: 550;">Fried Fish with Turmeric Powder</div>`,
          link: "https://www.youtube.com/watch?v=CPh0O-cMMTk",
        },
        {
          content: `<img src="/image/recommend-recipes3.0.jpg" style="width:75%" /><br><br><div style="font-size:20px; color:#8F9194; font-family: MyriadVariableConcept; font-weight: 550;">Five Spice Egg</div>`,
          link: "https://www.youtube.com/watch?v=OkFoCETCDGM",
        },
        {
          content: `<img src="/image/recommend-recipes4.0.jpg" style="width:75%" /><br><br><div style="font-size:20px; color:#8F9194; font-family: MyriadVariableConcept; font-weight: 550;">Chinese Five Spice Pork Chops</div>`,
          link: "https://www.youtube.com/watch?v=_zH54YI5DL8",
        },
        {
          content: `<img src="/image/recommend-recipes5.0.jpg" style="width:75%" /><br><br><div style="font-size:20px; color:#8F9194; font-family: MyriadVariableConcept; font-weight: 550;">Turmeric Mango Smoothie</div>`,
          link: "https://www.youtube.com/watch?v=dDpctJMWpBM",
        },
      ],
    };
  },

  created() {
    this.first_page_load = true;
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  updated() {
    if (this.$route.path === "/") {
      this.mouse_hover = true;
    }
    if (this.$route.path === "/about" && this.mouse_hover === true) {
      this.pageScroll();
    }
  },

  methods: {
    pageScroll() {
      if (this.$route.path == "/about") {
        var i = 0;
        var int = setInterval(function () {
          window.scrollTo(0, i);
          i += 25;

          if (window.innerWidth >= 1920) {
            // pc
            if (i >= 825) {
              clearInterval(int);
            }
          } else if (window.innerWidth >= 1760) {
            // notebook
            if (i >= 800) {
              clearInterval(int);
            }
          } else if (window.innerWidth >= 1600) {
            // notebook
            if (i >= 750) {
              clearInterval(int);
            }
          } else if (window.innerWidth >= 1440) {
            // notebook
            if (i >= 700) {
              clearInterval(int);
            }
          } else if (window.innerWidth >= 1366) {
            // ipad pro
            if (i >= 575) {
              clearInterval(int);
            }
          } else if (window.innerWidth >= 1280) {
            // notebook mini
            if (i >= 675) {
              clearInterval(int);
            }
          } else if (window.innerWidth >= 1112) {
            // ipad
            if (i >= 500) {
              clearInterval(int);
            }
          } else if (window.innerWidth >= 1024) {
            // ipad
            if (i >= 475) {
              clearInterval(int);
            }
          } else if (window.innerWidth >= 834) {
            // ipad
            if (i >= 425) {
              clearInterval(int);
            }
          } else if (window.innerWidth >= 768) {
            // ipad
            if (i >= 400) {
              clearInterval(int);
            }
          } else if (window.innerWidth >= 428) {
            // i12 pro max
            if (i >= 300) {
              clearInterval(int);
            }
          } else if (window.innerWidth >= 375) {
            // Ii6s
            if (i >= 275) {
              clearInterval(int);
            }
          } else {
            clearInterval(int);
          }
        }, 2);
      }
    },

    mouseOver(change_image) {
      this.mouse_hover = false;
      return change_image;
    },

    mouseLeave(main_image) {
      return main_image;
    },
  },
};
</script>

<style>
.vueperslides__arrow {
  color: #686868;
  font-size: 15px;
}

.vueperslides__arrow svg {
  stroke-width: 2px;
}

@media screen and (max-width: 428px) {
  .vueperslides__arrow {
    font-size: 8px;
  }

  .vueperslides__bullet {
    margin: 0.5rem;
  }

  .vueperslides__bullet .default {
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  .vueperslides__bullet--active .default {
    border-width: 5px;
  }
}
</style>

<style scoped>
.home_pc {
  display: block;
}

.home_moblie {
  display: none;
}

.content_box {
  margin: 18rem 20rem 0rem 20rem;
}

.topic {
  font-size: 45px;
}

.topic .dark_green {
  color: #688037;
  font-family: "GujaratiSangamMN-Bold";
}

.topic .light_green {
  color: #abc93b;
  font-family: "GujaratiSangamMN";
}

.about_us_detail {
  font-size: 20px;
  width: 100%;
  padding: 2rem 2rem 2rem 0;
  color: #8f9194;
  font-family: "MyriadVariableConcept";
}

.about_us_image {
  width: 100%;
}

.about_us_image img {
  width: 90%;
}

.about_us_logo img {
  padding-top: 2rem;
}

.about_us_logo .about_us_logo1 {
  width: 10%;
}

.about_us_logo .about_us_logo2 {
  width: 8%;
  margin-left: 1rem;
}

.products_pc {
  display: block;
}

.products_moblie {
  display: none;
}

.load_change_image {
  display: none;
}

.products_details img {
  width: 50%;
  cursor: pointer;
}

.products_details img:hover {
  animation-name: animation;
  animation-duration: 2s;
}

.products_details font {
  font-size: 20px;
  color: #8f9194;
  margin-top: 1rem;
  font-family: "MyriadVariableConcept";
  font-weight: 550;
}

.loadpage_products_detail {
  width: 100%;
  cursor: pointer;
}

.loadpage_products_detail:hover {
  text-decoration: underline;
}

.image_wrapper {
  position: relative;
  min-height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.best_seller {
  margin-top: 2rem;
  margin-right: 10rem;
  margin-bottom: 0.5rem;
  background: #abc93b;
  display: table;
  padding: 0 0.5rem;
  font-size: 20px;
  color: #ffffff;
  font-family: "Sathu";
  font-weight: normal;
}

.not_best_seller {
  content: "";
  position: relative;
  padding-bottom: 4.9rem;
}

.recommend_recipes_pc {
  display: block;
}

.recommend_recipes_moblie {
  display: none;
}

.item_video {
  position: absolute;
  width: 100%;
  opacity: 0.2;
  z-index: -10;
  padding: 0px;
  margin: 0px;
}

.wrapper_video {
  width: 100%;
}

.recommend_recipes {
  padding: 16rem 0;
}

.recommend_recipes_details {
  padding-top: 2rem;
}

.recommend_recipes_details a {
  color: inherit;
}

.recommend_recipes_details img {
  width: 80%;
}

.recommend_recipes_details font {
  font-size: 20px;
  color: #78787b;
  font-family: "MyriadVariableConcept";
  font-weight: 550;
}

.recommend_recipes_button {
  text-align: center;
  padding-top: 4rem;
}

.recommend_recipes_button font {
  font-family: "Epilogue-Bold";
  font-size: 20px;
  border-radius: 40px;
  padding: 0.5rem 1.5rem;
  background: #abc93b;
  color: #ffffff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
}

.recommend_recipes_button font:hover {
  cursor: pointer;
  text-decoration: underline;
}

.where_to_buy {
  margin: 10rem 20rem;
}

.where_to_buy_details img {
  width: 50%;
}

.where_to_buy_details a {
  margin-top: 6rem;
}

@keyframes animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1760px) {
  .content_box {
    margin: 18rem 15rem 0rem 15rem;
  }

  .recommend_recipes {
    padding: 15rem 0;
  }

  .recommend_recipes_button font {
    padding: 0.4rem 0.8rem;
  }

  .where_to_buy {
    margin: 8rem 15rem;
  }

  .where_to_buy_details a {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 1600px) {
  .content_box {
    margin: 16rem 10rem 0rem 10rem;
  }

  .recommend_recipes {
    padding: 13rem 0;
  }

  .recommend_recipes_button font {
    padding: 0.3rem 0.75rem;
  }

  .where_to_buy {
    margin: 4rem 10rem;
  }
}

@media screen and (max-width: 1440px) {
  .content_box {
    margin: 14rem 6.75rem 0rem 6.75rem;
  }

  .topic {
    font-size: 38px;
  }

  .about_us_detail {
    font-size: 18px;
  }

  .about_us_image {
    width: 100%;
    margin-left: 4rem;
  }

  .about_us_image img {
    width: 90%;
  }

  .image_wrapper {
    min-height: 280px;
  }

  .best_seller {
    font-size: 18px;
    margin-bottom: 0.25rem;
  }

  .not_best_seller {
    padding-bottom: 4.1rem;
  }

  .recommend_recipes {
    padding: 10rem 0;
  }

  .recommend_recipes_details img {
    width: 70%;
  }

  .recommend_recipes_details font {
    font-size: 18px;
  }

  .recommend_recipes_button font {
    font-size: 18px;
  }

  .where_to_buy {
    margin: 6rem 6.75rem;
  }
}

@media screen and (max-width: 1366px) {
  .content_box {
    margin: 4rem 5rem 4rem 5rem;
  }

  .where_to_buy {
    margin: 2rem 5rem 4rem 5rem;
  }
}

@media screen and (max-width: 1280px) {
  .content_box {
    margin: 12em 2rem 0rem 2rem;
  }

  .topic {
    font-size: 30px;
  }

  .where_to_buy {
    margin: 0rem 2rem 4rem 2rem;
  }
}

@media screen and (max-width: 1112px) {
  .home_pc {
    display: none;
  }

  .home_moblie {
    display: block;
  }

  .content_box {
    margin: 4rem 2rem;
  }

  .about_us_detail {
    font-size: 17px;
    padding: 2rem 1.25rem 2rem 0;
  }

  .about_us_image {
    width: 90%;
    margin-left: 2rem;
  }

  .about_us_image img {
    width: 90%;
  }

  .image_wrapper {
    min-height: 215px;
  }

  .best_seller {
    font-size: 18px;
    margin-bottom: 0.25rem;
    margin-right: 6rem;
  }

  .recommend_recipes {
    padding: 5rem 0;
  }

  .recommend_recipes_details img {
    width: 70%;
  }

  .recommend_recipes_details font {
    font-size: 16px;
  }

  .recommend_recipes_button font {
    padding: 0.2rem 0.5rem;
    font-size: 16px;
  }

  .where_to_buy {
    margin: 6rem 2rem 2rem 2rem;
  }

  .where_to_buy_details a {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .about_us_detail {
    font-size: 16px;
    padding: 4rem 1.5rem 4rem 0;
  }

  .about_us_image {
    width: 80%;
    margin-left: 3rem;
  }

  .where_to_buy {
    margin: 4rem 2rem 2rem 2rem;
  }
}

@media screen and (max-width: 834px) {
  .content_box {
    margin: 4rem 1rem;
  }

  .about_us_detail {
    font-size: 13.5px;
    padding: 2rem 0.25rem 2rem 0;
  }

  .about_us_image {
    width: 90%;
    margin-left: 1rem;
  }

  .about_us_image img {
    width: 100%;
  }

  .products_details font {
    font-size: 12.5px;
  }

  .image_wrapper {
    min-height: 160px;
  }

  .best_seller {
    font-size: 14px;
    margin-bottom: 0.25rem;
  }

  .not_best_seller {
    padding-bottom: 3.6rem;
  }

  .recommend_recipes {
    padding: 2rem 0;
  }

  .recommend_recipes_details font {
    font-size: 12.5px;
  }

  .recommend_recipes_button font {
    font-size: 12.5px;
  }

  .where_to_buy {
    margin: 5rem 1rem 2rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .about_us_detail {
    font-size: 12.5px;
    padding: 4rem 0.25rem 4rem 0;
  }

  .about_us_image {
    width: 80%;
    margin-left: 1rem;
  }

  .where_to_buy {
    margin: 4rem 1rem 2rem 1rem;
  }
}

@media screen and (max-width: 428px) {
  .content_box {
    margin: 6rem 0.5rem;
  }

  .topic {
    font-size: 25px;
  }

  .about_us_detail {
    padding: 1rem 0;
    font-size: 14px;
  }

  .about_us_image {
    display: none;
  }

  .about_us_logo img {
    padding-top: 1rem;
  }

  .about_us_logo .about_us_logo1 {
    width: 30%;
  }

  .about_us_logo .about_us_logo2 {
    width: 25%;
    margin-left: 1rem;
  }

  .products_pc {
    display: none;
  }

  .products_moblie {
    display: block;
  }

  .products {
    margin-top: 8rem;
  }

  .recommend_recipes_details {
    padding-top: 1rem;
  }

  .recommend_recipes_pc {
    display: none;
  }

  .recommend_recipes_moblie {
    display: block;
  }

  .recommend_recipes {
    padding: 0;
  }

  .where_to_buy {
    margin: 0rem 0.5rem 2rem 0.5rem;
  }
}
</style>