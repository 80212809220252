<template>
  <div>
    <div class="NavbarPC">
      <NavbarPC />
    </div>
    <div class="NavbarMoblie">
      <NavbarMoblie />
    </div>
  </div>
</template>

<script>
import NavbarPC from "@/components/Navbar_PC.vue";
import NavbarMoblie from "@/components/Navbar_Moblie.vue";

export default {
  components: {
    NavbarPC,
    NavbarMoblie,
  },
};
</script>

<style scoped>
.NavbarPC {
  display: block;
}
.NavbarMoblie {
  display: none;
}

@media screen and (max-width: 991px) {
  .NavbarPC {
    display: none;
  }
  .NavbarMoblie {
    display: block;
  }
}
</style>