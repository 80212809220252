<template>
  <router-view oncontextmenu="return false;" ondragstart="return false;"></router-view>
</template>

<script>
export default {};
</script>

<style>
@font-face {
  font-family: "MyriadVariableConcept";
  src: url("../public/font/MyriadVariableConcept.otf");
}
@font-face {
  font-family: "GujaratiSangamMN";
  src: url("../public/font/GujaratiSangamMN.ttf");
}
@font-face {
  font-family: "GujaratiSangamMN-Bold";
  src: url("../public/font/GujaratiSangamMN-Bold.ttf");
}
@font-face {
  font-family: "Sathu";
  src: url("../public/font/Sathu.ttf");
}
@font-face {
  font-family: "Epilogue-Bold";
  src: url("../public/font/Epilogue-Bold.ttf");
}
@font-face {
  font-family: "Epilogue-Medium";
  src: url("../public/font/Epilogue-Medium.ttf");
}
@font-face {
  font-family: "Epilogue-Regular";
  src: url("../public/font/Epilogue-Regular.ttf");
}
@font-face {
  font-family: "Epilogue-Light";
  src: url("../public/font/Epilogue-Light.ttf");
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}
</style>