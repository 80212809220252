<template>
  <div>
    <Navbar />

    <div class="recommend_recipes">
      <div class="recommend_recipes_topic">
        <font class="dark_green">RECOMMEND </font>
        <font class="light_green">RECIPES</font>
      </div>

      <div class="recommend_recipes_details">
        <div class="container-fluid">
          <div class="row">
            <div
              class="col-sm-4"
              align="center"
              v-for="(data, key) in arraydata"
              :key="key"
            >
              <a :href="data.link" target="_blank">
                <img :src="data.image" />
              </a>

              <div class="recommend_recipes_menu">
                <font class="text-center">
                  <a :href="data.link" target="_blank">
                    {{ data.name }}
                  </a>
                </font>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Recipes",
  components: {
    Navbar,
  },
  data() {
    return {
      arraydata: [
        {
          image: "/image/recommend-recipes1.0.jpg",
          name: "Stir Fried Crab with Curry Powder",
          link: "https://www.youtube.com/watch?v=PZ_eEWqmQp8",
        },
        {
          image: "/image/recommend-recipes2.0.jpg",
          name: "Fried Fish with Turmeric Powder",
          link: "https://www.youtube.com/watch?v=CPh0O-cMMTk",
        },
        {
          image: "/image/recommend-recipes3.0.jpg",
          name: "Five Spice Egg",
          link: "https://www.youtube.com/watch?v=OkFoCETCDGM",
        },
        {
          image: "/image/recommend-recipes4.0.jpg",
          name: "Chinese Five Spice Pork Chops",
          link: "https://www.youtube.com/watch?v=_zH54YI5DL8",
        },
        {
          image: "/image/recommend-recipes5.0.jpg",
          name: "Turmeric Mango Smoothie",
          link: "https://www.youtube.com/watch?v=dDpctJMWpBM",
        },
      ],
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.recommend_recipes {
  margin: 4rem 20rem;
}

.recommend_recipes_topic {
  font-size: 45px;
}

.recommend_recipes_topic .dark_green {
  font-family: "GujaratiSangamMN-Bold";
}

.recommend_recipes_topic .light_green {
  font-family: "GujaratiSangamMN";
}

.recommend_recipes_details {
  padding-top: 1rem;
}

.recommend_recipes_details a {
  color: inherit;
}

.recommend_recipes_details font {
  font-family: "MyriadVariableConcept";
  font-weight: 550;
  font-size: 20px;
  color: #78787b;
}

.recommend_recipes_details img {
  width: 100%;
}

.recommend_recipes_menu {
  margin: 0.5rem 0 2rem 0;
}

.dark_green {
  color: #688037;
}

.light_green {
  color: #abc93b;
}

@media screen and (max-width: 1760px) {
  .recommend_recipes {
    margin: 4rem 15rem;
  }
}

@media screen and (max-width: 1600px) {
  .recommend_recipes {
    margin: 4rem 10rem;
  }
}

@media screen and (max-width: 1440px) {
  .recommend_recipes {
    margin: 4rem 6.75rem;
  }

  .recommend_recipes_topic {
    font-size: 38px;
  }

  .recommend_recipes_details font {
    font-size: 18px;
  }
}

@media screen and (max-width: 1366px) {
  .recommend_recipes {
    margin: 4rem 5rem;
  }
}

@media screen and (max-width: 1280px) {
  .recommend_recipes {
    margin: 2rem;
  }

  .recommend_recipes_topic {
    font-size: 30px;
  }

  .recommend_recipes_details font {
    font-size: 16px;
  }
}

@media screen and (max-width: 834px) {
  .recommend_recipes {
    margin: 1rem;
  }

  .recommend_recipes_details font {
    font-size: 14px;
  }
}

@media screen and (max-width: 428px) {
  .recommend_recipes {
    margin: 1rem 0.5rem;
  }

  .recommend_recipes_topic {
    font-size: 25px;
  }
}
</style>