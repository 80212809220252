var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"border_nav"},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"wrapper_nav"},[_c('li',{staticClass:"logo"},[_c('div',{on:{"click":function($event){$event.preventDefault();_vm.$router.push('/').catch(() => {})}}},[_c('img',{attrs:{"src":"/image/logo.png"}})])]),_c('li',{staticClass:"link_page",style:({ color: _vm.$route.name == 'Home' ? '#688037' : '#8f9194' }),on:{"click":function($event){$event.preventDefault();_vm.$router.push('/').catch(() => {})}}},[_vm._v(" Home ")]),_c('li',{staticClass:"link_page",style:({ color: _vm.$route.name == 'About' ? '#688037' : '#8f9194' }),on:{"click":function($event){$event.preventDefault();_vm.$router.push('/about').catch(() => {})}}},[_vm._v(" About Us ")]),_c('li',{staticClass:"dropdown link_page",on:{"click":function($event){return _vm.toggle_dropdown()}}},[_c('font',{class:[
              _vm.checkclickdropdown === true ? 'dark_green_color' : 'gray_color',
            ],style:({
              color:
                _vm.$route.name == 'Seasoning_Blends' ||
                _vm.$route.name == 'Industrial_Products'
                  ? '#688037'
                  : '#8f9194',
            })},[_vm._v("Products")]),_c('div',{class:[
              _vm.checkclickdropdown === true ? 'show_dropdown' : 'hide_dropdown',
            ]},[_c('div',{style:({
                color:
                  _vm.$route.name == 'Seasoning_Blends' ? '#688037' : '#8f9194',
              }),on:{"click":function($event){$event.preventDefault();_vm.$router.push('/seasoning_blends').catch(() => {})}}},[_vm._v(" Seasoning Blends ")]),_c('div',{style:({
                color:
                  _vm.$route.name == 'Industrial_Products'
                    ? '#688037'
                    : '#8f9194',
              }),on:{"click":function($event){$event.preventDefault();_vm.$router.push('/industrial_products').catch(() => {})}}},[_vm._v(" Industrial Products ")])])],1),_c('li',{staticClass:"link_page",style:({ color: _vm.$route.name == 'Recipes' ? '#688037' : '#8f9194' }),on:{"click":function($event){$event.preventDefault();_vm.$router.push('/recipes').catch(() => {})}}},[_vm._v(" Recipes ")]),_c('li',{staticClass:"link_page",style:({
            color: _vm.$route.name == 'Contact_US' ? '#688037' : '#8f9194',
          }),on:{"click":function($event){$event.preventDefault();_vm.$router.push('/contact_us').catch(() => {})}}},[_vm._v(" Contact Us ")]),_vm._m(0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"icon"},[_c('div',{staticClass:"group_icon"},[_c('li',[_c('a',{attrs:{"href":"https://www.facebook.com/SpoonyThailand","target":"_blank"}},[_c('img',{attrs:{"src":"/image/navbar_facebook.svg"}})])]),_c('li',[_c('a',{attrs:{"href":"https://lin.ee/cbFKxdY","target":"_blank"}},[_c('img',{attrs:{"src":"/image/navbar_line.svg"}})])]),_c('li',[_c('a',{attrs:{"href":"https://www.instagram.com/spoonythailand/?hl=en","target":"_blank"}},[_c('img',{attrs:{"src":"/image/navbar_instagram.svg"}})])]),_c('li',[_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCsEEqKOPnH9lKZkkHcgz2ZQ","target":"_blank"}},[_c('img',{attrs:{"src":"/image/navbar_youtube.svg"}})])])])])
}]

export { render, staticRenderFns }