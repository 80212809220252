import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Recipes from '../views/Recipes.vue'
import Contact_US from '../views/Contact_US.vue'
import Industrial_Products from '../views/Products/Industrial_Products.vue'
import Seasoning_Blends from '../views/Products/Seasoning_Blends.vue'
import Products_Detail from '../views/Products/Products_Detail.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
}, {
    path: '/recipes',
    name: 'Recipes',
    component: Recipes
}, {
    path: '/contact_us',
    name: 'Contact_US',
    component: Contact_US
}, {
    path: '/seasoning_blends',
    name: 'Seasoning_Blends',
    component: Seasoning_Blends
}, {
    path: '/industrial_products',
    name: 'Industrial_Products',
    component: Industrial_Products
}, {
    path: '/products_detail/:id',
    name: 'Products_Detail',
    component: Products_Detail
}, {
    path: '/about',
    name: 'About',
    component: Home
},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router