<template>
  <div>
    <Navbar />

    <div class="contact_us">
      <div class="contact_us_topic">
        <font class="dark_green">CONTACT </font>
        <font class="light_green">US</font>
      </div>

      <div class="contact_us_details">
        <div class="container-fluid m-0 p-0">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
              <font class="company_name">
                Double Ducks (2002) Co.,Ltd <br />
              </font>
              <font class="gray">
                1320,1322 Soi.Thienta-lay26 Year 6-1, <br />
                Bangkhunthien-chaytalay Rd.,Tha-kham, <br />
                Bangkhunthien Bangkok 10150, Thailand <br />
              </font>
              <br />
              <font class="dark_green">Tel: </font>
              <font class="gray"
                >+668-3639-5641, +669-4496-9655, +668-5145-1665</font
              >
              <br />
              <font class="dark_green">E-mail: </font>
              <font class="gray">sp.spoony@gmail.com</font><br />
              <font class="dark_green">Line Id: </font>
              <font class="gray">@spoony</font><br />
              <br />
              <font class="dark_green">Opening Hours</font>
              <br />
              <font class="gray">
                Monday – Saturday <br />
                8:00 – 17:00 <br />
                Closed on Sunday <br />
              </font>
            </div>

            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 send_message"
            >
              <div class="container-fluid m-0 p-0">
                <form @submit="myFunction">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <input
                        type="text"
                        ref="Name"
                        placeholder="Name"
                        v-model="form.Name"
                        maxlength="40"
                      />
                      <input
                        type="text"
                        ref="Email"
                        placeholder="Email"
                        v-model="form.Email"
                        @keypress="email($event)"
                        maxlength="35"
                      />
                      <input
                        type="text"
                        ref="Phone"
                        placeholder="Phone"
                        v-model="form.Phone"
                        @keypress="phone_number($event)"
                        maxlength="10"
                      />
                    </div>
                    <div class="col-12 col-md-6">
                      <textarea
                        ref="Message"
                        placeholder="Message..."
                        v-model="form.Message"
                        maxlength="255"
                      ></textarea>
                    </div>
                    <div class="col-12">
                      <button type="submit">SUBMIT</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="google_map_box">
          <div class="google_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.864987334736!2d100.42301961532381!3d13.605056990442538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2bcd132d524b3%3A0xb7a0d17e84e0d7d6!2z4Lia4Lij4Li04Lip4Lix4LiXIOC5gOC4m-C5h-C4lOC4hOC4ueC5iCAoMjAwMikg4LiI4Liz4LiB4Lix4LiU!5e0!3m2!1sth!2sth!4v1608889920918!5m2!1sth!2sth"
              width="100%"
              height="100%"
              frameborder="0"
              style="border: 0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import axios from "axios";

export default {
  name: "Contact_US",
  components: {
    Navbar,
  },

  data() {
    return {
      form: {
        Name: "",
        Email: "",
        Phone: "",
        Message: "",
      },
    };
  },

  methods: {
    phone_number: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },

    email: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode >= 45 && charCode <= 46) ||
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 64 && charCode <= 90) ||
        (charCode >= 97 && charCode <= 122) ||
        charCode == 95
      ) {
        return true;
      } else {
        evt.preventDefault();
      }
    },

    myFunction: function (evt) {
      const regexName = new RegExp(/^[a-zA-Z0-9ก-๙/,&./ ]*$/);
      var CheckName = regexName.test(this.form.Name);

      const regexEmail = new RegExp(
        /^[A-Z0-9.%+-]+@[A-Z0-9./*/-]+.[A-Z]{2,4}$/i
      );
      var CheckEmail = regexEmail.test(this.form.Email);

      const regexPhone = new RegExp(/^[0-9]{9,}$/);
      var CheckPhone = regexPhone.test(this.form.Phone);

      if (this.form.Name == "") {
        alert("กรุณากรอกชื่อ");
        this.$refs.Name.focus();
      } else if (CheckName === false) {
        alert("กรุณากรอกชื่อให้ถูกต้อง");
        this.$refs.Name.focus();
      } else if (this.form.Email == "") {
        alert("กรุณากรอกอีเมล");
        this.$refs.Email.focus();
      } else if (CheckEmail === false) {
        alert("กรุณากรอกอีเมลให้ถูกต้อง");
        this.$refs.Email.focus();
      } else if (this.form.Phone == "") {
        alert("กรุณากรอกเบอร์โทรศัพท์");
        this.$refs.Phone.focus();
      } else if (CheckPhone === false) {
        alert("กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง");
        this.$refs.Phone.focus();
      } else if (this.form.Message == "") {
        alert("กรุณากรอกรายละเอียด (Message)");
        this.$refs.Message.focus();
      } else {
        // const api = "http://localhost:8000/contact_us";
        const api = "https://spoony-backend.herokuapp.com/contact_us";
        axios.post(api, this.form).then((response) => {});
        this.form = {
          Name: "",
          Email: "",
          Phone: "",
          Message: "",
        };
        alert("ส่งข้อมูลสำเร็จ");
      }

      evt.preventDefault();
    },
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.contact_us {
  margin: 4rem 20rem;
}

.contact_us_topic {
  font-size: 45px;
}

.contact_us_topic .dark_green {
  font-family: "GujaratiSangamMN-Bold";
}

.contact_us_topic .light_green {
  font-family: "GujaratiSangamMN";
}

.contact_us_details {
  padding-top: 4rem;
  font-size: 20px;
}

.contact_us_details .company_name {
  font-family: "MyriadVariableConcept";
  font-weight: 700;
  color: #688037;
}

.contact_us_details .dark_green {
  font-family: "MyriadVariableConcept";
  font-weight: 600;
}

.contact_us_details .gray {
  font-family: "MyriadVariableConcept";
  font-weight: 400;
}

.contact_us_details input {
  width: 100%;
  border: 3px solid #add139;
  padding: 5px 15px;
  margin-bottom: 15px;
  font-size: 18px;
  font-family: "Epilogue-Light";
  user-select: text;
}

.contact_us_details textarea {
  resize: none;
  width: 100%;
  height: 159px;
  border: 3px solid #add139;
  padding: 5px 15px;
  margin-bottom: 15px;
  font-size: 18px;
  font-family: "Epilogue-Light";
  user-select: text;
}

.contact_us_details button {
  float: right;
  border-radius: 30px;
  padding: 0rem 1rem;
  margin-top: 2rem;
  background: #abc93b;
  color: #ffffff;
  border: none;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  font-family: "Epilogue-Regular";
  font-size: 16px;
}

.contact_us_details button:hover {
  text-decoration: underline;
}

.send_message {
  margin: 0;
  padding: 0;
}

.google_map {
  width: 1000px;
  height: 400px;
  border: 1px solid black;
}

.google_map_box {
  padding-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}

.dark_green {
  color: #688037;
}

.light_green {
  color: #abc93b;
}

.gray {
  color: #8f9194;
}

@media screen and (max-width: 1760px) {
  .contact_us {
    margin: 4rem 15rem;
  }
}

@media screen and (max-width: 1600px) {
  .contact_us {
    margin: 4rem 10rem;
  }
}

@media screen and (max-width: 1440px) {
  .contact_us {
    margin: 4rem 6.75rem;
  }

  .contact_us_topic {
    font-size: 38px;
  }

  .contact_us_details {
    padding-top: 4rem;
    font-size: 18px;
  }

  .contact_us_details input {
    font-size: 16px;
  }

  .contact_us_details textarea {
    height: 149px;
    font-size: 16px;
  }

  .contact_us_details button {
    padding: 0rem 0.5rem;
    font-size: 15px;
  }

  .google_map {
    width: 800px;
    height: 350px;
  }
}

@media screen and (max-width: 1366px) {
  .contact_us {
    margin: 4rem 5rem;
  }
}

@media screen and (max-width: 1280px) {
  .contact_us {
    margin: 2rem;
  }

  .contact_us_topic {
    font-size: 30px;
  }

  .contact_us_details {
    padding-top: 4rem;
    font-size: 16px;
  }

  .contact_us_details input {
    font-size: 16px;
  }

  .contact_us_details textarea {
    height: 148px;
    font-size: 16px;
  }

  .google_map {
    width: 700px;
    height: 300px;
  }
}

@media screen and (max-width: 1112px) {
  .send_message {
    padding: 2rem 1rem 0 1rem;
  }
}

@media screen and (max-width: 834px) {
  .contact_us {
    margin: 1rem;
  }

  .google_map {
    width: 550px;
    height: 250px;
  }
}

@media screen and (max-width: 428px) {
  .contact_us {
    margin: 1rem;
  }

  .contact_us_topic {
    font-size: 25px;
  }

  .contact_us_details {
    padding-top: 1rem;
    font-size: 14px;
  }

  .contact_us_details input {
    font-size: 14px;
  }

  .contact_us_details textarea {
    font-size: 14px;
    height: 150px;
  }

  .contact_us_details button {
    margin-top: 0rem;
  }

  .send_message {
    padding: 1rem;
  }

  .google_map {
    width: 350px;
    height: 250px;
  }

  .google_map_box {
    padding-top: 2rem;
  }
}
</style>