<template>
  <div>
    <Navbar />

    <div class="seasoning_blends">
      <div class="seasoning_blends_topic1">
        <font class="dark_green">PRODUCTS</font>
      </div>
      <div class="seasoning_blends_topic2">
        <font class="light_green">SEASONING BLENDS</font>
      </div>

      <div class="seasoning_blends_pc">
        <div class="seasoning_blends_details">
          <div class="container-fluid">
            <div class="row">
              <div
                class="col-sm-4"
                align="center"
                v-for="(data, key) in arraydata"
                :key="key"
              >
                <div class="best_seller" v-if="data.best_seller !== ''">
                  {{ data.best_seller }}
                </div>
                <div class="not_best_seller" v-else></div>
                <div
                  class="image_wrapper d-flex justify-content-center align-items-center"
                >
                  <img
                    :src="data.image"
                    @mouseover="data.image = data.change_image"
                    @mouseleave="data.image = data.main_image"
                    @click.prevent="
                      $router
                        .push(`/products_detail/${data.id}`)
                        .catch(() => {})
                    "
                  />
                </div>

                <div
                  class="d-flex flex-wrap justify-content-center align-items-end"
                >
                  <font
                    class="loadpage_products_detail"
                    @click.prevent="
                      $router
                        .push(`/products_detail/${data.id}`)
                        .catch(() => {})
                    "
                    >{{ data.detail }}</font
                  >

                  <div class="line"></div>
                  <!-- <font class="w-100">{{ data.price }}</font> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="seasoning_blends_moblie">
        <vueper-slides
          class="no-shadow"
          :slide-ratio="10 / 9"
          :bullets="false"
          :touchable="false"
          lazy
          lazy-load-on-drag
        >
          <vueper-slide
            v-for="(slide, key) in slides_products"
            :key="key"
            :content="slide.content"
            :link="slide.link"
          />
        </vueper-slides>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "Seasoning_Blends",
  components: {
    Navbar,
    VueperSlides,
    VueperSlide,
  },

  data() {
    return {
      arraydata: [
        {
          id: "1",
          best_seller: "BEST SELLER",
          image: "/image/products1.0.png",
          main_image: "/image/products1.0.png",
          change_image: "/image/products1.1.png",
          detail: "CHINESE FIVE SPICE BLEND",
          price: "฿ 39.00",
        },
        {
          id: "2",
          best_seller: "BEST SELLER",
          image: "/image/products2.0.png",
          main_image: "/image/products2.0.png",
          change_image: "/image/products2.1.png",
          detail: "CURRY POWDER",
          price: "฿ 39.00",
        },
        {
          id: "3",
          best_seller: "",
          image: "/image/products3.0.png",
          main_image: "/image/products3.0.png",
          change_image: "/image/products3.1.png",
          detail: "TURMERIC POWDER",
          price: "฿ 39.00",
        },
      ],
      slides_products: [
        {
          content: `<div style="display: table; font-size: 25px; margin-left: 2rem; margin-bottom: 0.5rem; padding: 0 1rem; color: #ffffff; background: #abc93b; font-family:Sathu; font-weight: normal;">BEST SELLER</div><img src="/image/products1.0.png" style="width:50%" /><br><br><div style="font-size:14px; color:#8F9194; font-family: MyriadVariableConcept; font-weight: 550;">CHINESE FIVE SPICE BLEND<br>฿ 39.00</div>`,
          link: "/products_detail/1",
        },
        {
          content: `<div style="display: table; font-size: 25px; margin-left: 2rem; margin-bottom: 0.5rem; padding: 0 1rem; color: #ffffff; background: #abc93b; font-family: Sathu; font-weight: normal;">BEST SELLER</div><img src="/image/products2.0.png" style="width:50%" /><br><br><div style="font-size:14px; color:#8F9194; font-family: MyriadVariableConcept; font-weight: 550;">CURRY POWDER<br>฿ 39.00</div>`,
          link: "/products_detail/2",
        },
        {
          content: `<div style="margin-top:3rem;"></div><img src="/image/products3.0.png" style="width:50%" /><br><br><div style="font-size:14px; color:#8F9194; font-family: MyriadVariableConcept; font-weight: 550;">TURMERIC POWDER<br>฿ 39.00</div>`,
          link: "/products_detail/3",
        },
      ],
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.seasoning_blends {
  margin: 4rem 20rem;
}

.seasoning_blends_topic1 {
  font-size: 45px;
  font-family: "GujaratiSangamMN-Bold";
}

.seasoning_blends_topic2 {
  font-size: 30px;
  margin-top: -15px;
  margin-bottom: 2rem;
  font-family: "MyriadVariableConcept";
  font-weight: 250;
}

.seasoning_blends_pc {
  display: block;
}
.seasoning_blends_moblie {
  display: none;
}

.seasoning_blends_details font {
  font-size: 20px;
  color: #8f9194;
  font-family: "MyriadVariableConcept";
  font-weight: 550;
}

.seasoning_blends_details img {
  width: 50%;
  cursor: pointer;
  margin-bottom: 2rem;
}

.seasoning_blends_details img:hover {
  animation-name: animation;
  animation-duration: 2s;
}

.best_seller {
  margin-top: 2rem;
  margin-right: 10rem;
  margin-bottom: 0.3rem;
  background: #abc93b;
  display: table;
  padding: 0 0.5rem;
  font-size: 20px;
  color: #ffffff;
  font-family: "Sathu";
  font-weight: normal;
}

.not_best_seller {
  content: "";
  position: relative;
  padding-bottom: 4.9rem;
}

.image_wrapper {
  position: relative;
  min-height: 330px;
}

.loadpage_products_detail {
  font-weight: bold;
  width: 100%;
  cursor: pointer;
}

.loadpage_products_detail:hover {
  text-decoration: underline;
}

.line {
  content: "";
  background: #8f9194;
  width: 10%;
  height: 2.5px;
  margin: 0.75rem 0;
  border-radius: 5px;
}

.dark_green {
  color: #688037;
}

.light_green {
  color: #abc93b;
}

@keyframes animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1760px) {
  .seasoning_blends {
    margin: 4rem 15rem;
  }

  .image_wrapper {
    min-height: 325px;
  }
}

@media screen and (max-width: 1600px) {
  .seasoning_blends {
    margin: 4rem 10rem;
  }

  .best_seller {
    margin-right: 8rem;
  }

  .image_wrapper {
    min-height: 325px;
  }
}

@media screen and (max-width: 1440px) {
  .seasoning_blends {
    margin: 4rem 6.75rem;
  }

  .seasoning_blends_topic1 {
    font-size: 38px;
  }

  .seasoning_blends_topic2 {
    font-size: 25px;
  }

  .seasoning_blends_details font {
    font-size: 18px;
  }

  .image_wrapper {
    min-height: 310px;
  }

  .best_seller {
    font-size: 20px;
    margin-right: 8rem;
    margin-bottom: 0.25rem;
  }

  .not_best_seller {
    padding-bottom: 4.4rem;
  }
}

@media screen and (max-width: 1366px) {
  .seasoning_blends {
    margin: 4rem 5rem;
  }

  .image_wrapper {
    min-height: 310px;
  }

  .best_seller {
    margin-right: 10rem;
  }
}

@media screen and (max-width: 1280px) {
  .seasoning_blends {
    margin: 2rem;
  }

  .seasoning_blends_topic1 {
    font-size: 30px;
  }

  .seasoning_blends_topic2 {
    font-size: 20px;
  }

  .image_wrapper {
    min-height: 310px;
  }

  .best_seller {
    margin-right: 6rem;
  }
}

@media screen and (max-width: 1112px) {
  .seasoning_blends_details font {
    font-size: 16px;
  }

  .image_wrapper {
    min-height: 270px;
  }

  .best_seller {
    font-size: 18px;
    margin-right: 8rem;
    margin-bottom: 0.25rem;
  }

  .not_best_seller {
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 834px) {
  .seasoning_blends {
    margin: 1rem;
  }

  .image_wrapper {
    min-height: 210px;
  }

  .best_seller {
    margin-right: 4rem;
  }
}

@media screen and (max-width: 428px) {
  .seasoning_blends {
    margin: 1rem 0.5rem;
  }

  .seasoning_blends_topic1 {
    font-size: 25px;
  }

  .seasoning_blends_topic2 {
    font-size: 20px;
    margin-top: -10px;
  }

  .seasoning_blends_pc {
    display: none;
  }

  .seasoning_blends_moblie {
    display: block;
  }
}
</style>