<template>
  <div>
    <nav class="border_nav">
      <div class="container">
        <ul class="wrapper_nav">
          <li class="logo">
            <div @click.prevent="$router.push('/').catch(() => {})">
              <img src="/image/logo.png" />
            </div>
          </li>
          <li
            class="link_page"
            @click.prevent="$router.push('/').catch(() => {})"
            :style="{ color: $route.name == 'Home' ? '#688037' : '#8f9194' }"
          >
            Home
          </li>
          <li
            class="link_page"
            @click.prevent="$router.push('/about').catch(() => {})"
            :style="{ color: $route.name == 'About' ? '#688037' : '#8f9194' }"
          >
            About Us
          </li>
          <li class="dropdown link_page" @click="toggle_dropdown()">
            <font
              :class="[
                checkclickdropdown === true ? 'dark_green_color' : 'gray_color',
              ]"
              :style="{
                color:
                  $route.name == 'Seasoning_Blends' ||
                  $route.name == 'Industrial_Products'
                    ? '#688037'
                    : '#8f9194',
              }"
              >Products</font
            >
            <div
              :class="[
                checkclickdropdown === true ? 'show_dropdown' : 'hide_dropdown',
              ]"
            >
              <div
                @click.prevent="
                  $router.push('/seasoning_blends').catch(() => {})
                "
                :style="{
                  color:
                    $route.name == 'Seasoning_Blends' ? '#688037' : '#8f9194',
                }"
              >
                Seasoning Blends
              </div>
              <div
                @click.prevent="
                  $router.push('/industrial_products').catch(() => {})
                "
                :style="{
                  color:
                    $route.name == 'Industrial_Products'
                      ? '#688037'
                      : '#8f9194',
                }"
              >
                Industrial Products
              </div>
            </div>
          </li>
          <li
            class="link_page"
            @click.prevent="$router.push('/recipes').catch(() => {})"
            :style="{ color: $route.name == 'Recipes' ? '#688037' : '#8f9194' }"
          >
            Recipes
          </li>
          <li
            class="link_page"
            @click.prevent="$router.push('/contact_us').catch(() => {})"
            :style="{
              color: $route.name == 'Contact_US' ? '#688037' : '#8f9194',
            }"
          >
            Contact Us
          </li>
          <li class="icon">
            <div class="group_icon">
              <li>
                <a
                  href="https://www.facebook.com/SpoonyThailand"
                  target="_blank"
                  ><img src="/image/navbar_facebook.svg"
                /></a>
              </li>
              <li>
                <a href="https://lin.ee/cbFKxdY" target="_blank"
                  ><img src="/image/navbar_line.svg"
                /></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/spoonythailand/?hl=en"
                  target="_blank"
                  ><img src="/image/navbar_instagram.svg"
                /></a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCsEEqKOPnH9lKZkkHcgz2ZQ"
                  target="_blank"
                  ><img src="/image/navbar_youtube.svg"
                /></a>
              </li>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar_PC",

  data() {
    return {
      checkclickdropdown: false,
    };
  },

  methods: {
    toggle_dropdown() {
      this.checkclickdropdown = !this.checkclickdropdown;
    },
  },
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  font-family: "Epilogue-Medium";
}

nav {
  max-width: 100%;
  height: 0px;
  display: flex;
  align-items: center;
}

.border_nav {
  border-bottom: 1px solid #d2d4d6;
  padding: 4rem 0;
}

.container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.wrapper_nav {
  list-style: none;
  display: flex;
  align-items: center;
}

.wrapper_nav li {
  margin: 0 1.4rem;
  color: #8f9194;
  font-weight: bold;
  font-size: 18px;
  transition: ease 0.5s;
  user-select: none;
}

.wrapper_nav li:hover,
.wrapper_nav li font:hover {
  color: #688037 !important;
  cursor: pointer;
}

.wrapper_nav li img {
  width: 50px;
}

.group_icon {
  display: flex;
}

.group_icon li {
  margin: 0 0.6rem;
}

.group_icon li img {
  width: 25px;
}

.show_dropdown {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 150px;
  font-size: 14px;
  font-weight: initial;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.show_dropdown div {
  color: #8f9194;
  text-decoration: none;
  display: block;
  padding: 0.25rem 0.5rem;
}

.show_dropdown div:hover {
  background-color: #abc93b;
}

.hide_dropdown {
  display: none;
}

.dropdown:hover .hide_dropdown {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 150px;
  font-size: 14px;
  font-weight: initial;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.hide_dropdown div {
  color: #8f9194;
  text-decoration: none;
  display: block;
  padding: 0.25rem 0.5rem;
}

.hide_dropdown div:hover {
  background-color: #abc93b;
}

.wrapper_nav .logo {
  margin-right: 4rem;
}

.wrapper_nav .icon {
  margin-left: 4rem;
}

.wrapper_nav .link_page {
  margin-top: 0.8rem;
}

@media screen and (max-width: 1600px) {
  .wrapper_nav li {
    font-size: 16px;
  }

  .wrapper_nav li img {
    width: 45px;
  }

  .group_icon li img {
    width: 20px;
  }

  .border_nav {
    padding: 3.5rem 0;
  }

  .dark_green_color {
    color: #688037;
  }

  .gray_color {
    color: #8f9194;
  }
}

@media screen and (max-width: 1366.98px) {
  .dropdown:hover .hide_dropdown {
    display: none;
  }

  .wrapper_nav li:hover,
  .wrapper_nav li font:hover {
    color: #8f9194;
  }
}
</style>