<template>
  <div>
    <nav>
      <div class="container-fluid border_nav">
        <div class="row h-100">
          <div class="col-3 d-flex align-items-center justify-content-center">
            <div @click.prevent="$router.push('/').catch(() => {})">
              <img class="logo" src="/image/logo.png" />
            </div>
          </div>
          <div class="col-7 d-flex align-items-center">
            <div class="row text-center">
              <div class="col-3">
                <a
                  href="https://www.facebook.com/SpoonyThailand"
                  target="_blank"
                >
                  <img class="icon" src="/image/navbar_facebook.svg" />
                </a>
              </div>
              <div class="col-3">
                <a href="https://lin.ee/cbFKxdY" target="_blank">
                  <img class="icon" src="/image/navbar_line.svg" />
                </a>
              </div>
              <div class="col-3">
                <a
                  href="https://www.instagram.com/spoonythailand/?hl=en"
                  target="_blank"
                >
                  <img class="icon" src="/image/navbar_instagram.svg" />
                </a>
              </div>
              <div class="col-3">
                <a
                  href="https://www.youtube.com/channel/UCsEEqKOPnH9lKZkkHcgz2ZQ"
                  target="_blank"
                >
                  <img class="icon" src="/image/navbar_youtube.svg" />
                </a>
              </div>
            </div>
          </div>
          <div
            class="col-2 d-flex align-items-center justify-content-end hamberger"
          >
            <i class="fa fa-bars fa-2x" @click="show_list_item()"></i>
          </div>
        </div>
      </div>
    </nav>

    <div
      :class="[
        checkclickhamberger === true ? 'show_list_item' : 'hide_list_item',
      ]"
    >
      <div class="d-flex justify-content-end">
        <i class="fa fa-times fa-2x" @click="show_list_item()"></i>
      </div>
      <div class="d-flex justify-content-center">
        <ul>
          <li>
            <div
              @click.prevent="$router.push('/').catch(() => {})"
              @click="closehamberger"
              :style="{ color: $route.name == 'Home' ? '#688037' : '#8f9194' }"
            >
              Home
            </div>
          </li>
          <li>
            <div
              class="about_us"
              @click.prevent="$router.push('/about').catch(() => {})"
              @click="closehamberger"
              :style="{ color: $route.name == 'About' ? '#688037' : '#8f9194' }"
            >
              About Us
            </div>
          </li>
          <li class="dropdown" v-on:click="toggle_dropdown()">
            <div>
              <font
                :class="[
                  checkclickdropdown === true ? 'light_green_color' : '',
                ]"
                :style="{
                  color:
                    $route.name == 'Seasoning_Blends' ||
                    $route.name == 'Industrial_Products'
                      ? '#688037'
                      : '#8f9194',
                }"
                >Products</font
              >
              <div
                :class="[
                  checkclickdropdown === true
                    ? 'show_dropdown'
                    : 'hide_dropdown',
                ]"
              >
                <div
                  @click.prevent="
                    $router.push('/seasoning_blends').catch(() => {})
                  "
                  :style="{
                    color:
                      $route.name == 'Seasoning_Blends' ? '#688037' : '#8f9194',
                  }"
                >
                  Seasoning Blends
                </div>
                <div
                  @click.prevent="
                    $router.push('/industrial_products').catch(() => {})
                  "
                  :style="{
                    color:
                      $route.name == 'Industrial_Products'
                        ? '#688037'
                        : '#8f9194',
                  }"
                >
                  Industrial Products
                </div>
              </div>
            </div>
          </li>
          <li>
            <div
              @click.prevent="$router.push('/recipes').catch(() => {})"
              :style="{
                color: $route.name == 'Recipes' ? '#688037' : '#8f9194',
              }"
            >
              Recipes
            </div>
          </li>
          <li>
            <div
              @click.prevent="$router.push('/contact_us').catch(() => {})"
              :style="{
                color: $route.name == 'Contact_US' ? '#688037' : '#8f9194',
              }"
            >
              Contact Us
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar_moblie",

  data() {
    return {
      checkclickhamberger: false,
      checkclickdropdown: false,
    };
  },

  methods: {
    show_list_item() {
      this.checkclickhamberger = !this.checkclickhamberger;

      if (this.checkclickhamberger === false) {
        this.checkclickdropdown = false;
      }
    },

    toggle_dropdown() {
      this.checkclickdropdown = !this.checkclickdropdown;
    },

    closehamberger() {
      this.checkclickhamberger = false;
      this.checkclickdropdown = false;
    },
  },
};
</script>

<style scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

img:hover, i:hover {
  cursor: pointer;
}

.border_nav {
  height: 130px;
  width: 100%;
  user-select: none;
  background: #fff;
  z-index: 10;
  border-bottom: 1px solid #d2d4d6;
}

.logo {
  width: 50px;
}

.icon {
  width: 50%;
}

.hamberger {
  color: #858989;
}

.hide_list_item {
  display: none;
}

.show_list_item {
  display: block;
  width: 75%;
  height: 100%;
  background: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  border: 1px solid #cacaca;
  overflow-y: scroll;
}

.show_list_item i {
  color: #858989;
  margin: 3rem 1rem 1rem 0;
}

.show_list_item ul {
  margin: 0;
  padding: 0;
  font-family: "Epilogue-Medium";
  list-style: none;
}

.show_list_item ul li {
  padding-bottom: 2rem;
}

.show_list_item ul li div {
  color: #858989;
  font-size: 22px;
  user-select: none;
}

.show_list_item .hide_dropdown {
  display: none;
}

.show_list_item .show_dropdown {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 170px;
  font-weight: initial;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.show_list_item .show_dropdown div {
  color: #8f9194;
  text-decoration: none;
  font-size: 16px;
  display: block;
  padding: 0.5rem 0.5rem;
}

.light_green_color {
  color: #abc93b;
}

@media screen and (max-width: 575px) {
  .logo {
    width: 40px;
  }

  .icon {
    width: 100%;
  }
}
</style>