<template>
  <div>
    <Navbar />

    <div class="products_datail">
      <div class="container-fluid">
        <!-- pc -->
        <div class="row products_datail_pc">
          <div class="col-xl-2 col-md-2 d-flex flex-column align-items-center">
            <div
              :class="[
                select_image == products_detail[0].image_main
                  ? 'image_frame image_frame_active'
                  : 'image_frame',
              ]"
              v-on:click="myFunction(products_detail[0].image_main)"
            >
              <img :src="products_detail[0].image_main" />
            </div>
            <div
              :class="[
                select_image == products_detail[0].image_sub
                  ? 'image_frame image_frame_active'
                  : 'image_frame',
              ]"
              v-on:click="myFunction(products_detail[0].image_sub)"
            >
              <img :src="products_detail[0].image_sub" />
            </div>
          </div>

          <div class="col-xl-6 col-md-6 d-flex justify-content-center">
            <div class="show_image">
              <img :src="select_image" />
            </div>
          </div>

          <div class="col-xl-4 col-md-4">
            <div class="information">
              <div class="information_name">
                {{ products_detail[0].name_product }}
              </div>
              <!-- <div class="information_price">
                {{ products_detail[0].price }}
              </div> -->
              <div class="information_datail">
                {{ products_detail[0].detail }}
              </div>
            </div>
          </div>
        </div>

        <!-- moblie -->
        <div class="row products_datail_moblie">
          <div class="d-flex align-items-center justify-content-center">
            <div class="show_image">
              <img :src="select_image" />
            </div>
          </div>

          <div
            class="d-flex flex-row align-items-center justify-content-center p-3"
          >
            <div
              :class="[
                select_image == products_detail[0].image_main
                  ? 'image_frame image_frame_active'
                  : 'image_frame',
              ]"
              v-on:click="myFunction(products_detail[0].image_main)"
            ></div>
            <div
              :class="[
                select_image == products_detail[0].image_sub
                  ? 'image_frame image_frame_active'
                  : 'image_frame',
              ]"
              v-on:click="myFunction(products_detail[0].image_sub)"
            ></div>
          </div>

          <div class="information">
            <div class="information_name">
              {{ products_detail[0].name_product }}
            </div>
            <!-- <div class="information_price">
              {{ products_detail[0].price }}
            </div> -->
            <div class="information_datail">
              {{ products_detail[0].detail }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Products_Detail",
  components: {
    Navbar,
  },

  computed: {
    products_detail() {
      return this.arraydata_products.filter(
        (n) => n.id == this.$route.params.id && n
      );
    },
  },

  data() {
    return {
      arraydata_products: [
        {
          id: "1",
          image_main: "/image/products1.0.png",
          image_sub: "/image/products1.1.png",
          name_product: "CHINESE FIVE SPICE BLEND",
          price: "฿ 39.00",
          detail:
            "Chinese Five Spice Blend (50g.)\n- No Preservatives\n- No Artificial Colors\n- No MSG",
        },
        {
          id: "2",
          image_main: "/image/products2.0.png",
          image_sub: "/image/products2.1.png",
          name_product: "CURRY POWDER",
          price: "฿ 39.00",
          detail:
            "Curry Powder (50g.)\n- No Preservatives\n- No Artificial Colors\n- No MSG",
        },
        {
          id: "3",
          image_main: "/image/products3.0.png",
          image_sub: "/image/products3.1.png",
          name_product: "TURMERIC POWDER",
          price: "฿ 39.00",
          detail: "Turmeric Powder (50g.)\n- No Artificial Colors",
        },
      ],

      select_image: "",
    };
  },

  mounted() {
    this.select_image = this.products_detail[0].image_main;
    window.scrollTo(0, 0);
  },

  methods: {
    myFunction: function (image) {
      this.select_image = image;
    },
  },
};
</script>

<style scoped>
.products_datail {
  margin: 4rem 20rem;
}

.products_datail_moblie {
  display: none;
}

.image_frame {
  width: 100px;
  height: 100px;
  border: 1px solid #cacccd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 2rem;
  user-select: none;
}

.image_frame:hover {
  border: 1px solid #688037;
  opacity: 0.6;
  transition: ease 0.2s;
}

.image_frame img {
  height: 100%;
  padding: 2px;
}

.image_frame_active {
  border: 1px solid #abc93b;
}

.show_image {
  width: 500px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show_image img {
  height: 100%;
}

.information {
  color: #8f9194;
  font-family: "MyriadVariableConcept";
}

.information_name {
  font-size: 25px;
  padding-top: 2rem;
  font-weight: 550;
}

.information_price {
  font-size: 22px;
  padding-top: 2rem;
  font-weight: 550;
}

.information_datail {
  font-size: 20px;
  padding-top: 2rem;
  white-space: pre-wrap;
}

@media screen and (max-width: 1760px) {
  .products_datail {
    margin: 4rem 15rem;
  }

  .show_image {
    width: 475px;
    height: 475px;
  }
}

@media screen and (max-width: 1600px) {
  .products_datail {
    margin: 4rem 10rem;
  }

  .show_image {
    width: 450px;
    height: 450px;
  }
}

@media screen and (max-width: 1440px) {
  .products_datail {
    margin: 4rem 6.75rem;
  }

  .image_frame {
    width: 75px;
    height: 75px;
  }

  .show_image {
    width: 400px;
    height: 400px;
  }

  .information_name {
    font-size: 22px;
  }

  .information_price {
    font-size: 20px;
  }

  .information_datail {
    font-size: 18px;
  }
}

@media screen and (max-width: 1366px) {
  .products_datail {
    margin: 4rem 5rem;
  }
}

@media screen and (max-width: 1280px) {
  .products_datail {
    margin: 4rem 2rem;
  }

  .show_image {
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 834px) {
  .products_datail {
    margin: 4rem 1rem;
  }

  .image_frame {
    width: 50px;
    height: 50px;
  }

  .show_image {
    width: 300px;
    height: 300px;
  }

  .information_name {
    font-size: 18px;
  }

  .information_price {
    font-size: 17px;
  }

  .information_datail {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .products_datail_pc {
    display: none;
  }

  .products_datail_moblie {
    display: block;
  }

  .image_frame {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: none;
    background: #c4c5c5;
    margin: 0.3rem;
  }

  .image_frame:hover {
    border: 0px;
    opacity: 1;
  }

  .image_frame_active {
    background: #8f9194;
    border: 0px;
  }
}

@media screen and (max-width: 428px) {
  .products_datail {
    margin: 2rem 0.5rem;
  }

  .information {
    margin: 0 2rem;
  }
}
</style>