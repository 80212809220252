var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar'),_c('div',{staticClass:"products_datail"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row products_datail_pc"},[_c('div',{staticClass:"col-xl-2 col-md-2 d-flex flex-column align-items-center"},[_c('div',{class:[
              _vm.select_image == _vm.products_detail[0].image_main
                ? 'image_frame image_frame_active'
                : 'image_frame',
            ],on:{"click":function($event){return _vm.myFunction(_vm.products_detail[0].image_main)}}},[_c('img',{attrs:{"src":_vm.products_detail[0].image_main}})]),_c('div',{class:[
              _vm.select_image == _vm.products_detail[0].image_sub
                ? 'image_frame image_frame_active'
                : 'image_frame',
            ],on:{"click":function($event){return _vm.myFunction(_vm.products_detail[0].image_sub)}}},[_c('img',{attrs:{"src":_vm.products_detail[0].image_sub}})])]),_c('div',{staticClass:"col-xl-6 col-md-6 d-flex justify-content-center"},[_c('div',{staticClass:"show_image"},[_c('img',{attrs:{"src":_vm.select_image}})])]),_c('div',{staticClass:"col-xl-4 col-md-4"},[_c('div',{staticClass:"information"},[_c('div',{staticClass:"information_name"},[_vm._v(" "+_vm._s(_vm.products_detail[0].name_product)+" ")]),_c('div',{staticClass:"information_datail"},[_vm._v(" "+_vm._s(_vm.products_detail[0].detail)+" ")])])])]),_c('div',{staticClass:"row products_datail_moblie"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"show_image"},[_c('img',{attrs:{"src":_vm.select_image}})])]),_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-center p-3"},[_c('div',{class:[
              _vm.select_image == _vm.products_detail[0].image_main
                ? 'image_frame image_frame_active'
                : 'image_frame',
            ],on:{"click":function($event){return _vm.myFunction(_vm.products_detail[0].image_main)}}}),_c('div',{class:[
              _vm.select_image == _vm.products_detail[0].image_sub
                ? 'image_frame image_frame_active'
                : 'image_frame',
            ],on:{"click":function($event){return _vm.myFunction(_vm.products_detail[0].image_sub)}}})]),_c('div',{staticClass:"information"},[_c('div',{staticClass:"information_name"},[_vm._v(" "+_vm._s(_vm.products_detail[0].name_product)+" ")]),_c('div',{staticClass:"information_datail"},[_vm._v(" "+_vm._s(_vm.products_detail[0].detail)+" ")])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }