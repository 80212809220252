<template>
  <div>
    <Navbar />

    <div class="industrial_products">
      <div class="industrial_products_topic1">
        <font class="dark_green">INDUSTRIAL </font>
        <font class="light_green">PRODUCTS</font>
      </div>
      <div class="industrial_products_topic2">
        <font class="light_green">SPICES & HERBS</font>
      </div>

      <div class="industrial_products_details">
        <div class="container-fluid">
          <div class="row">
            <div
              class="col-6 col-md-4 products_margin"
              align="center"
              v-for="(data, key) in arraydata"
              :key="key"
            >
              <img :src="data.image" />
              <br />
              <font>{{ data.name }}</font>

              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Industrial_Products",
  components: {
    Navbar,
  },

  data() {
    return {
      arraydata: [
        {
          image: "/image/industrial-products1.png",
          name: "CHINESE FIVE SPICE BLEND",
        },
        {
          image: "/image/industrial-products2.png",
          name: "CURRY POWDER",
        },
        {
          image: "/image/industrial-products3.png",
          name: "TURMERIC POWDER",
        },
        {
          image: "/image/industrial-products4.png",
          name: "CINNAMON POWDER",
        },
        {
          image: "/image/industrial-products5.png",
          name: "STAR ANISE POWDER",
        },
        {
          image: "/image/industrial-products6.png",
          name: "CORIANDER SEED POWDER",
        },
        {
          image: "/image/industrial-products7.png",
          name: "WHITE PEPPER SEED POWDER",
        },
        {
          image: "/image/industrial-products8.png",
          name: "LICORICE POWDER",
        },
        {
          image: "/image/industrial-products9.png",
          name: "SICHUAN PEPPER POWDER",
        },
        {
          image: "/image/industrial-products10.png",
          name: "GINGER POWDER",
        },
        {
          image: "/image/industrial-products11.png",
          name: "GALANGAL POWDER",
        },
        {
          image: "/image/industrial-products12.png",
          name: "ONION POWDER",
        },
        {
          image: "/image/industrial-products13.png",
          name: "LEMON GRASS POWDER",
        },
        {
          image: "/image/industrial-products14.png",
          name: "ORANGE PEEL POWDER",
        },
        {
          image: "/image/industrial-products15.png",
          name: "CUMIN POWDER",
        },
        {
          image: "/image/industrial-products16.png",
          name: "CINNAMON",
        },
        {
          image: "/image/industrial-products17.png",
          name: "STAR ANISE",
        },
        {
          image: "/image/industrial-products18.png",
          name: "CORIANDER SEED",
        },
        {
          image: "/image/industrial-products19.png",
          name: "SWEET FENNEL",
        },
        {
          image: "/image/industrial-products20.png",
          name: "CUMIN",
        },
        {
          image: "/image/industrial-products21.png",
          name: "SICHUAN PEPPER",
        },
        {
          image: "/image/industrial-products22.png",
          name: "WHITE PEPPER SEED",
        },
        {
          image: "/image/industrial-products23.png",
          name: "BLACK PEPPER SEED",
        },
        {
          image: "/image/industrial-products24.png",
          name: "CLOVE",
        },
        {
          image: "/image/industrial-products25.png",
          name: "FENUGREEK",
        },
        {
          image: "/image/industrial-products26.png",
          name: "CARDAMOM",
        },
        {
          image: "/image/industrial-products27.png",
          name: "ORANGE PEEL",
        },
        {
          image: "/image/industrial-products28.png",
          name: "ROSEMARY",
        },
        {
          image: "/image/industrial-products29.png",
          name: "MACE",
        },
        {
          image: "/image/industrial-products30.png",
          name: "GALANGAL",
        },
      ],
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.industrial_products {
  margin: 4rem 20rem;
}

.industrial_products_topic1 {
  font-size: 45px;
}

.industrial_products_topic2 {
  font-size: 30px;
  margin-top: -15px;
  margin-bottom: 2rem;
}

.industrial_products_topic1 .dark_green {
  font-family: "GujaratiSangamMN-Bold";
}

.industrial_products_topic1 .light_green {
  font-family: "GujaratiSangamMN";
}

.industrial_products_topic2 .light_green {
  font-family: "MyriadVariableConcept";
  font-weight: 250;
}

.industrial_products_details {
  margin-top: 4rem;
}

.industrial_products_details font {
  font-size: 20px;
  color: #8f9194;
  margin-top: 1rem;
  font-family: "MyriadVariableConcept";
  font-weight: 550;
}

.industrial_products_details img {
  width: 65%;
  margin-bottom: 2rem;
}

.products_margin {
  margin-bottom: 4rem;
}

.line {
  content: "";
  background: #8f9194;
  width: 10%;
  height: 2.5px;
  margin-top: 1.5rem;
  border-radius: 5px;
}

.dark_green {
  color: #688037;
}

.light_green {
  color: #abc93b;
}

@media screen and (max-width: 1760px) {
  .industrial_products {
    margin: 4rem 15rem;
  }
}

@media screen and (max-width: 1600px) {
  .industrial_products {
    margin: 4rem 10rem;
  }
}

@media screen and (max-width: 1440px) {
  .industrial_products {
    margin: 4rem 6.75rem;
  }

  .industrial_products_topic1 {
    font-size: 38px;
  }

  .industrial_products_topic2 {
    font-size: 25px;
  }

  .industrial_products_details font {
    font-size: 18px;
  }
}

@media screen and (max-width: 1366px) {
  .industrial_products {
    margin: 4rem 5rem;
  }
}

@media screen and (max-width: 1280px) {
  .industrial_products {
    margin: 2rem;
  }

  .industrial_products_topic1 {
    font-size: 30px;
  }

  .industrial_products_topic2 {
    font-size: 20px;
  }

  .industrial_products_details font {
    font-size: 16px;
  }
}

@media screen and (max-width: 834px) {
  .industrial_products {
    margin: 1rem;
  }
}

@media screen and (max-width: 428px) {
  .industrial_products {
    margin: 1rem 0.5rem;
  }

  .industrial_products_topic1 {
    font-size: 25px;
  }

  .industrial_products_topic2 {
    font-size: 20px;
    margin-top: -10px;
    margin-bottom: 1rem;
  }

  .industrial_products_details font {
    font-size: 14px;
  }

  .industrial_products_details img {
    margin-bottom: 1rem;
  }

  .products_margin {
    padding: 0;
    margin-bottom: 1.5rem;
  }

  hr.line {
    margin: 1rem 0 0 0;
  }
}
</style>